<template>
    <!-- Start Footer Area -->
    <footer class="footer-area bg-f7fafd">
        <div class="container">
            <div class="row">
                <div class="col-lg-3 col-md-6 col-sm-6">
                    <div class="single-footer-widget">
                        <div class="logo">
                            <a href="/">
                                <img src="../../assets/img/logo.png" alt="logo" />
                            </a>
                        </div>
                        <p style="text-align: justify;">{{ $t('Do you have an innovative idea and need help to develop it? At Startblock we are here to help you. We are the blockchain experts you have been looking for.') }}</p>
                    </div>
                </div>


                <div class="col-lg-3 col-md-6 col-sm-6">
                    <div class="single-footer-widget">    
                    </div>
                </div>

                <div class="col-lg-3 col-md-6 col-sm-6">
                    <div class="single-footer-widget pl-5">
                        <h3>{{ $t('Company') }}</h3>
                        <ul class="list">
                            <li> <a> <router-link to="/about">{{ $t('About Us') }}</router-link> </a></li>                 
                            <!-- <li><a href="#">Servicios</a></li> -->
                            <!-- <li><a href="#">Caracteristicas</a></li> -->
                            <li><a href="/contact">{{ $t('Contact Us') }}</a></li>
                        </ul>
                    </div>
                </div>

                <div class="col-lg-3 col-md-6 col-sm-6">
                    <div class="single-footer-widget">
                        <h3>{{ $t('Contact Us') }}</h3>
                        
                        <ul class="footer-contact-info">
                            <li><feather type="mail"></feather> Email: <a href="mailto:info@startblock.tech?Subject=Tenemos%20una%20idea">info@startblock.tech</a></li>
                        </ul>
                        <ul class="social-links">
                            <li><a href="https://www.instagram.com/startblock.tech/" target="blank_" class="instagram"><feather type="instagram"></feather></a></li>
                            <li><a href="https://www.linkedin.com/company/startblock-blockchain-solutions" target="blank_" class="linkedin"><feather type="linkedin"></feather></a></li>             
                        </ul>
                    </div>
                </div>

                <div class="col-lg-12 col-md-12">
                    <div class="copyright-area">
                        <p>Copyright &copy;2020 StartBlock. {{ $t('All rights reserved.') }}</p>
                    </div>
                </div>
            </div>
        </div>

        <img src="../../assets/img/map.png" class="map" alt="map">
        <div class="shape1"><img src="../../assets/img/shape1.png" alt="shape"></div>
        <div class="shape8 rotateme"><img src="../../assets/img/shape2.png" alt="shape"></div>
        <!-- <div class="go-top"><feather type="arrow-up"></feather></div> -->

        <back-to-top bottom="50px" right="50px">
            <div class="go-top"><feather type="arrow-up"></feather></div>
        </back-to-top>
    </footer>
    <!-- End Footer Area -->
</template>

<script>
import BackToTop from 'vue-backtotop'
export default {
    name: 'Footer',
    components: { BackToTop }
}
</script>