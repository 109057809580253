<template>
    <!-- Start Cloud Hosting Services -->
    <section class="services-area ptb-80 bg-f7fafd">
        <div class="container">
            <div class="row h-100 justify-content-center align-items-center">
                <div class="col-lg-6 col-md-12 services-content">
                    <div class="section-title">
                        <h2>{{ $t('Tell us what you need') }}</h2>
                        <div class="bar"></div>
                        <p style="text-align: justify;">{{ $t('We know that every idea and business is different, so we have prepared a list of services that we can customize to your needs, no matter what phase you are in. Tell us what you need and we will get to work:') }}</p>
                    </div>

                    <div class="row">
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <div class="box">
                                <feather type="shield"></feather> {{ $t('Smart Contracts') }}
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <div class="box">
                                <feather type="link"></feather> {{ $t('Private Blockchain Networks') }}
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <div class="box">
                                <feather type="monitor"></feather> {{ $t('Mobile and Web DApps') }}
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <div class="box">
                                <feather type="database"></feather> {{ $t('Validation Systems') }}
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <div class="box">
                                <feather type="dollar-sign"></feather> {{ $t('Crypto Payment Solutions') }}
                            </div>
                        </div>
                        <!--
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <div class="box">
                                <feather type="mail"></feather> Email servers
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <div class="box">
                                <feather type="cloud"></feather> Hybrid cloud
                            </div>
                        </div>
                        -->
                    </div>
                </div>

                <div class="col-lg-6 col-md-12 services-right-image">
                    <img src="../../assets/img/services-right-image/book-self.png" class="wow fadeInDown" v-wow data-wow-delay="0.6s" alt="book-self">
                    <img src="../../assets/img/services-right-image/box.png" class="wow fadeInUp" v-wow data-wow-delay="0.6s" alt="box">
                    <img src="../../assets/img/services-right-image/chair.png" class="wow fadeInLeft" v-wow data-wow-delay="0.6s" alt="chair">
                    <img src="../../assets/img/services-right-image/cloud.png" class="wow zoomIn" v-wow data-wow-delay="0.6s" alt="cloud">
                    <img src="../../assets/img/services-right-image/cup.png" class="wow bounceIn" v-wow data-wow-delay="0.6s" alt="cup">
                    <img src="../../assets/img/services-right-image/flower-top.png" class="wow fadeInDown" v-wow data-wow-delay="0.6s" alt="flower">
                    <img src="../../assets/img/services-right-image/head-phone.png" class="wow zoomIn" v-wow data-wow-delay="0.6s" alt="head-phone">
                    <img src="../../assets/img/services-right-image/monitor.png" class="wow fadeInUp" v-wow data-wow-delay="0.6s" alt="monitor">
                    <img src="../../assets/img/services-right-image/mug.png" class="wow rotateIn" v-wow data-wow-delay="0.6s" alt="mug">
                    <img src="../../assets/img/services-right-image/table.png" class="wow fadeInUp" v-wow data-wow-delay="0.6s" alt="table">
                    <img src="../../assets/img/services-right-image/tissue.png" class="wow zoomIn" v-wow data-wow-delay="0.6s" alt="tissue">
                    <img src="../../assets/img/services-right-image/water-bottle.png" class="wow zoomIn" v-wow data-wow-delay="0.6s" alt="water-bottle">
                    <img src="../../assets/img/services-right-image/wifi.png" class="wow fadeInLeft" v-wow data-wow-delay="0.6s" alt="wifi">
                    <img src="../../assets/img/services-right-image/cercle-shape.png" class="bg-image rotateme" alt="shape">

                    <img src="../../assets/img/services-right-image/main-pic.png" class="wow fadeInUp" v-wow data-wow-delay="0.6s" alt="main-pic">
                </div>
            </div>
        </div>
    </section>
    <!-- End Cloud Hosting Services -->
</template>

<script>
export default {
    name: 'Services'
}
</script>
