<template>
  <!-- Start Team Area -->
  <section class="team-area ptb-80 bg-f9f6f6">
    <div class="container">
      <div class="section-title">
        <h2>{{ $t('Our Team') }}</h2>
        <div class="bar"></div>
      </div>
    </div>

    <div class="team-slides">
      <carousel
        :items="7"
        :autoplay="false"
        :loop="true"
        :autoplaySpeed="true"
        :autoplayTimeout="5000"
        :perPageCustom="[[0, 1],[576, 2],[768, 2],[1200, 4], [1500, 5]]"
      >
        <slide>
          <div class="single-team">
            <div class="team-image">
              <img src="../../assets/img/team-image/Tomas-base.png" alt="image" />
              <img src="../../assets/img/team-image/Tomas-hover.png" alt="hover-image" />
            </div>

            <div class="team-content">
              <div class="team-info">
                <h3>Tomás Sáez</h3>
                <span>{{ $t('CEO & Founder') }}</span>
              </div>

              <ul>
                <li>
                  <a href="https://www.linkedin.com/in/tomasmsaez/" target="_blank">
                    <feather type="linkedin"></feather>
                  </a>
                </li>
              </ul>

              <p
                style="text-align: justify;"
              >{{ $t('Entrepreneur and engineer focused on technology and project management areas. My job is to ensure that our team reaches its maximum potential and that our clients obtain the maximum results. Committed to a more connected and more decentralized world.') }}</p>
            </div>
          </div>
        </slide>

        <slide>
          <div class="single-team">
            <div class="team-image">
              <img src="../../assets/img/team-image/Fares-base.png" alt="image" />
              <img src="../../assets/img/team-image/Fares-hover.png" alt="hover-image" />
            </div>

            <div class="team-content">
              <div class="team-info">
                <h3>Fares Akel</h3>
                <span>{{ $t('CTO & Founder') }}</span>
              </div>

              <ul>
                <li>
                  <a href="https://www.linkedin.com/in/fakel/" target="_blank">
                    <feather type="linkedin"></feather>
                  </a>
                </li>
              </ul>

              <p
                style="text-align: justify;"
              >{{ $t("Software developer and innovator. Passionate about decentralized technologies. I seek to provide solutions to complex problems and I am a believer in free software. Part of my time is dedicated to guiding others in the areas of knowledge that I master.") }}</p>
            </div>
          </div>
        </slide>

        <slide>
          <div class="single-team">
            <div class="team-image">
              <img src="../../assets/img/team-image/Paola-base.png" alt="image" />
              <img src="../../assets/img/team-image/Paola-hover.png" alt="hover-image" />
            </div>

            <div class="team-content">
              <div class="team-info">
                <h3>Paola Rivero</h3>
                <span>{{ $t('Project Manager') }}</span>
              </div>

              <ul>
                <li>
                  <a href="https://www.linkedin.com/in/parb15/" target="_blank">
                    <feather type="linkedin"></feather>
                  </a>
                </li>
              </ul>

              <p
                style="text-align: justify;"
              >{{ $t('Production Engineer, creative and with experience in technology, consulting, supply chain and project management. I interpret the requirements in tangible products, closing the gap between an idea, its development and implementation.') }}</p>
            </div>
          </div>
        </slide>

        <slide>
          <div class="single-team">
            <div class="team-image">
              <img src="../../assets/img/team-image/Sergio-base.png" alt="image" />
              <img src="../../assets/img/team-image/Sergio-hover.png" alt="hover-image" />
            </div>

            <div class="team-content">
              <div class="team-info">
                <h3>Sergio Mota</h3>
                <span>{{ $t('Software Engineer') }}</span>
              </div>

              <ul>
                <li>
                  <a href="https://www.linkedin.com/in/sergio-m-79418262/" target="_blank">
                    <feather type="linkedin"></feather>
                  </a>
                </li>
              </ul>

              <p
                style="text-align: justify;"
              >{{ $t('As a developer, I consider myself skilled at listening, solving problems and complicated situations that arise along the way. In my current team I am the one in charge of supporting. For me, programming is equivalent to playing with legos.') }}</p>
            </div>
          </div>
        </slide>

        <slide>
          <div class="single-team">
            <div class="team-image">
              <img src="../../assets/img/team-image/Maurizio-base.png" alt="image" />
              <img src="../../assets/img/team-image/Maurizio-hover.png" alt="hover-image" />
            </div>

            <div class="team-content">
              <div class="team-info">
                <h3>Michele Sanseviero</h3>
                <span>{{ $t('Software Engineer') }}</span>
              </div>

              <ul>
                <li>
                  <a
                    href="https://www.linkedin.com/in/michele-sanseviero-6a44141b3"
                    target="_blank"
                  >
                    <feather type="linkedin"></feather>
                  </a>
                </li>
              </ul>

              <p
                style="text-align: justify;"
              >{{ $t('Working as a software engineer, I have participated in the creation of multiple projects and I have seen first-hand how ideas become reality. I have always found the magic that happens inside the screen of any device interesting.') }}</p>
            </div>
          </div>
        </slide>

        <slide>
          <div class="single-team">
            <div class="team-image">
              <img src="../../assets/img/team-image/Gabo-base.png" alt="image" />
              <img src="../../assets/img/team-image/Gabo-hover.png" alt="hover-image" />
            </div>

            <div class="team-content">
              <div class="team-info">
                <h3>Gabriel Oliveira</h3>
                <span>{{ $t('Software Engineer') }}</span>
              </div>

              <ul>
                <li>
                  <a href="https://www.linkedin.com/in/ngabrielantonio/" target="_blank">
                    <feather type="linkedin"></feather>
                  </a>
                </li>
              </ul>

              <p
                style="text-align: justify;"
              >{{ $t('I have the necessary abstraction to solve software problems, propose ideas and solutions that, as a team, allow projects to be successful. What I have achieved so far has been thanks to the motivation to make every dream possible.') }}</p>
            </div>
          </div>
        </slide>

        <slide>
          <div class="single-team">
            <div class="team-image">
              <img src="../../assets/img/team-image/Brenlys-base.png" alt="image" />
              <img src="../../assets/img/team-image/Brenlys-hover.png" alt="hover-image" />
            </div>

            <div class="team-content">
              <div class="team-info">
                <h3>Brenlys Quintero</h3>
                <span>{{ $t('Software Engineer') }}</span>
              </div>

              <ul>
                <li>
                  <a href="https://www.linkedin.com/in/brenlys-quintero-10b634197" target="_blank">
                    <feather type="linkedin"></feather>
                  </a>
                </li>
              </ul>

              <p
                style="text-align: justify;"
              >{{ $t('Web developer, motivated by the achievements of applying existing logic and technologies. With a willingness to learn multiple tools for better performance, and thus, together with my team, fulfill any proposed objective.') }}</p>
            </div>
          </div>
        </slide>

      </carousel>
    </div>
  </section>
  <!-- End Team Area -->
</template>


<script>
import { Carousel, Slide } from "vue-carousel";
export default {
  name: "Team",
  components: { Carousel, Slide },
};
</script>

<style lang="scss">
.single-team {
  height: 37rem;
}
</style>