<template>
    <!-- Start Partner Area -->
    <div class="partner-area partner-section">
        <div class="container text-center">
            <h5>{{ $t('Our Stack') }} </h5>
            <div class="partner-inner">
                <div class="row justify-content-between">
                    <div class="col-lg-2 col-md-3 col-6 col-sm-4">
                        <a>
                            <img src="../../assets/img/partner-img/partner-1.png" alt="nodejs-icon">
                            <img src="../../assets/img/partner-img/partner-hover1.png" alt="nodejs-icon-hover">
                        </a>
                    </div>

                    <div class="col-lg-2 col-md-3 col-6 col-sm-4">
                        <a>
                            <img src="../../assets/img/partner-img/partner-2.png" alt="mongodb-icon">
                            <img src="../../assets/img/partner-img/partner-hover2.png" alt="mongodb-icon-hover">
                        </a>
                    </div>

                    <div class="col-lg-2 col-md-3 col-6 col-sm-4">
                        <a>
                            <img src="../../assets/img/partner-img/partner-3.png" alt="postgresql-icon">
                            <img src="../../assets/img/partner-img/partner-hover3.png" alt="postgresql-icon-hover">
                        </a>
                    </div>

                    <div class="col-lg-2 col-md-3 col-6 col-sm-4">
                        <a>
                            <img src="../../assets/img/partner-img/partner-4.png" alt="mysql-icon">
                            <img src="../../assets/img/partner-img/partner-hover4.png" alt="mysql-icon-hover">
                        </a>
                    </div>
                </div>

                <div class="row justify-content-between" >
                   
                    <div class="col-lg-2 col-md-3 col-6 col-sm-4">
                        <a>
                            <img src="../../assets/img/partner-img/partner-5.png" alt="vuejs-icon">
                            <img src="../../assets/img/partner-img/partner-hover5.png" alt="vuejs-icon-hover">
                        </a>
                    </div>

                    <div class="col-lg-2 col-md-3 col-6 col-sm-4">
                        <a>
                            <img src="../../assets/img/partner-img/partner-6.png" alt="aws-icon">
                            <img src="../../assets/img/partner-img/partner-hover6.png" alt="aws-icon-hover">
                        </a>
                    </div>


                    <div class="col-lg-2 col-md-3 col-6 col-sm-4">
                        <a>
                            <img src="../../assets/img/partner-img/partner-9.png" alt="ethereum-icon">
                            <img src="../../assets/img/partner-img/partner-hover9.png" alt="ethereum-icon-hover">
                        </a>
                    </div>

                    <div class="col-lg-2 col-md-3 col-6 col-sm-4">
                        <a>
                            <img src="../../assets/img/partner-img/partner-10.png" alt="hyperledger-icon">
                            <img src="../../assets/img/partner-img/partner-hover10.png" alt="hyperledger-icon-hover">
                        </a>
                    </div>
                
                </div>
            </div>
        </div>
    </div>
    <!-- End Partner Area -->
</template>

<script>
export default {
    name: 'Partner'
}
</script>
