import * as firebase from "firebase/app";
import Vue from 'vue'
import VueRouter from 'vue-router';
import { BootstrapVue } from 'bootstrap-vue';
import VueFeather from 'vue-feather';
import vWow from 'v-wow';
import VueCarousel from 'vue-carousel';
import Toasted from 'vue-toasted';
import App from './App.vue'
import './assets/style/custom.scss';
import { router } from './router';
import store from './store';
import '@/plugins/polyglot';

// Add the Firebase services that you want to use
import "firebase/analytics";
import "firebase/performance";
import "firebase/firestore";
import "firebase/auth"

const firebaseConfig = {
  apiKey: "AIzaSyA22PzM6w3996drHFm-X8_xI7ax5EprMTk",
  authDomain: "startblocksite.firebaseapp.com",
  databaseURL: "https://startblocksite.firebaseio.com",
  projectId: "startblocksite",
  storageBucket: "startblocksite.appspot.com",
  messagingSenderId: "225008393413",
  appId: "1:225008393413:web:b7d772bd3bdc0d55ed64ac",
  measurementId: "G-KQGZ4Y8XTT"
};

firebase.initializeApp(firebaseConfig);
firebase.analytics();
firebase.performance();
firebase.auth().signInAnonymously();

export const db = firebase.firestore();
//const perf = firebase.performance();

Vue.config.productionTip = false

Vue.use(BootstrapVue);
Vue.use(VueFeather);
Vue.use(VueRouter);
Vue.use(vWow);
Vue.use(VueCarousel);
Vue.use(Toasted)

new Vue({
  store,
  router,
  render: h => h(App),
}).$mount('#app')
