<template>
    <div>
        <Banner></Banner>
        <BoxesArea></BoxesArea>
        <Services></Services>
        <ServicesArea></ServicesArea>
        <Team></Team>
        <!-- <Funfacts></Funfacts> -->
        <!-- <Works></Works> -->
        <!-- <Pricing></Pricing> -->
        <!-- <Feedback></Feedback> -->

        <!-- Start Ready To Talk Area -->
		<section class="ready-to-talk">
			<div class="container">
                <h3> {{ $t('Ready to start your project?') }}</h3>
                <p>{{ $t('We are ready to answer your questions') }}</p>
                <a class="btn btn-primary" > <router-link to="/contact">{{ $t('Contact Us') }}</router-link> </a>
			</div>
		</section>
        <Partner></Partner>
		<!-- End Ready To Talk Area -->
    </div>
</template>

<script>
import Banner from './it-startup/Banner';
import BoxesArea from './it-startup/BoxesArea';
import Services from './it-startup/Services';
import ServicesArea from './it-startup/ServicesArea';
import Team from './it-startup/Team';
// import Funfacts from './it-startup/Funfacts';
// import Works from './it-startup/Works';
// import Pricing from './it-startup/Pricing';
import Partner from './it-startup/Partner';
// import Feedback from './it-startup/Feedback';
export default {
    name: 'ITStartup',
    components: {
        Banner,
        BoxesArea,
        Services,
        ServicesArea,
        Team,
        // Funfacts,
        // Works,
        // Pricing,
        // Feedback,
        Partner
    }
}
</script>
