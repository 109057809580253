import VueRouter from 'vue-router';
// Pages
import ITStartup from './components/ITStartup';
const AboutStyleOne = () => import('./components/other-pages/about/AboutStyleOne');
/*import AboutStyleTwo from './components/other-pages/about/AboutStyleTwo';
import AboutStyleThree from './components/other-pages/about/AboutStyleThree';
import Features from './components/other-pages/features/Features';
import FeatureDetails from './components/other-pages/features/FeatureDetails';
import ServicesOne from './components/other-pages/services/ServicesOne';
import ServicesTwo from './components/other-pages/services/ServicesTwo';
import ServicesThree from './components/other-pages/services/ServicesThree';
import ServicesFour from './components/other-pages/services/ServicesFour';
import ServicesFive from './components/other-pages/services/ServicesFive';
import ServiceDetails from './components/other-pages/services/ServiceDetails';
import ProjectStyleOne from './components/other-pages/projects/ProjectStyleOne';
import ProjectStyleTwo from './components/other-pages/projects/ProjectStyleTwo';
import ProjectDetails from './components/other-pages/projects/ProjectDetails';
import Team from './components/other-pages/team/Team';
import Pricing from './components/other-pages/pricing/Pricing';
import Faq from './components/other-pages/faq/Faq';*/
const NotFound = () => import('./components/other-pages/not-found/NotFound');
/*import ComingSoon from './components/other-pages/coming-soon/ComingSoon';
import BlogGrid from './components/other-pages/blog/BlogGrid';
import BlogRightSidebar from './components/other-pages/blog/BlogRightSidebar';
import BlogGridTwo from './components/other-pages/blog/BlogGridTwo';
import BlogRightSidebarTwo from './components/other-pages/blog/BlogRightSidebarTwo';
import BlogGridThree from './components/other-pages/blog/BlogGridThree';
import BlogRightSidebarThree from './components/other-pages/blog/BlogRightSidebarThree';
import BlogDetails from './components/other-pages/blog/BlogDetails';*/
const Contact = () => import('./components/other-pages/contact/Contact');
/*import Shop from './components/other-pages/product/Shop';
import Cart from './components/other-pages/product/Cart';
import Checkout from './components/other-pages/product/Checkout';
import ItemDetails from './components/other-pages/product/ItemDetails';*/

export const router = new VueRouter({
    mode: 'history',
    linkExactActiveClass: 'active',
    scrollBehavior() {
      return { x: 0, y: 0 };
    },
  routes: [
      { path: '*', component: NotFound },
      { path: '/', component: ITStartup },
      { path: '/about', component: AboutStyleOne },
      { path: '/not-found', component: NotFound },
      { path: '/contact', component: Contact },

    ]
});