<template>
    <!-- Start Design & Development Services -->
    <section class="services-area ptb-80">
        <div class="container">
            <div class="row h-100 justify-content-center align-items-center">
                <div class="col-lg-6 col-md-12 services-left-image">
                    <img src="../../assets/img/services-left-image/big-monitor.png" class="wow fadeInDown" v-wow data-wow-delay="0.6s" alt="big-monitor">
                    <img src="../../assets/img/services-left-image/creative.png" class="wow fadeInUp" v-wow data-wow-delay="0.6s" alt="creative">
                    <img src="../../assets/img/services-left-image/developer.png" class="wow fadeInLeft" v-wow data-wow-delay="0.6s" alt="developer">
                    <img src="../../assets/img/services-left-image/flower-top.png" class="wow zoomIn" v-wow data-wow-delay="0.6s" alt="flower-top">
                    <img src="../../assets/img/services-left-image/small-monitor.png" class="wow bounceIn" v-wow data-wow-delay="0.6s" alt="small-monitor">
                    <img src="../../assets/img/services-left-image/small-top.png" class="wow fadeInDown" v-wow data-wow-delay="0.6s" alt="small-top">
                    <img src="../../assets/img/services-left-image/table.png" class="wow zoomIn" v-wow data-wow-delay="0.6s" alt="table">
                    <img src="../../assets/img/services-left-image/target.png" class="wow fadeInUp" v-wow data-wow-delay="0.6s" alt="target">
                    <img src="../../assets/img/services-left-image/cercle-shape.png" class="bg-image rotateme" alt="shape">

                    <img src="../../assets/img/services-left-image/main-pic.png" class="wow fadeInUp" v-wow data-wow-delay="0.6s" alt="main-pic">
                </div>

                <div class="col-lg-6 col-md-12 services-content">
                    <div class="section-title">
                        <h2>{{ $t('Agile and efficient') }}</h2>
                        <div class="bar"></div>
                        <p style="text-align: justify;">{{ $t('Our team of consultants and development experts is ready to take your idea from the conceptualization phase to a web app that will change your life and that of your clients.') }}</p>
                        <br>
                        <p style="text-align: justify;"> <b>{{ $t('Our priority is software that works,') }}</b> {{ $t('understanding that') }} <b>{{ $t('something functional is something proven,') }}</b> {{ $t('so you can always expect progress week by week that you’ll be able to test out.') }}</p>
                        <br>
                        <p style="text-align: justify;">{{ $t('We believe in collaboration with the client as part of the development within the extension of a mutual agreement.') }}</p>
                        <br>
                        <p style="text-align: justify;">{{ $t('We respond with ease and agility to changes without losing sight of the general vision that a defined strategy gives us.') }}</p>
                        <br>
                        <p style="text-align: justify;">{{ $t('Our highest priority is to satisfy the customer through early and continuous delivery of valuable software.') }}</p>
                        <br>
                        <p style="text-align: justify;">{{ $t("What are you waiting for? Let's talk about how we can change the world together.") }}</p>
                    </div>
                    <!--
                    <div class="row">
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <div class="box">
                                <feather type="layout"></feather> Responsive design
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <div class="box">
                                <feather type="code"></feather> React web development
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <div class="box">
                                <feather type="smartphone"></feather> Android apps development
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <div class="box">
                                <feather type="code"></feather> Laravel web development
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <div class="box">
                                <feather type="smartphone"></feather> iOS apps development
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <div class="box">
                                <feather type="pen-tool"></feather> UX/UI design
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <div class="box">
                                <feather type="shopping-cart"></feather> E-commerce development
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <div class="box">
                                <feather type="check-circle"></feather> Print ready design
                            </div>
                        </div>
                    </div>
                    -->
                </div>
            </div>
        </div>
    </section>
    <!-- End Design & Development Services -->
</template>

<script>
export default {
    name: 'ServicesArea'
}
</script>
