import Vue from 'vue';
import Polyglot from 'vue-polyglot';

Vue.use(Polyglot, {
  defaultLanguage: 'en',
  languagesAvailable: [
    'es',
    'en',
  ],
});

Vue.locales({
  es: {
    // Generals 
    'Home': 'Inicio',
    'About Us': 'Sobre Nosotros',
    'Contact Us': 'Contáctanos',
    'Our Team': 'Nuestro Equipo',
    'Our Email': 'Nuestro Email',
    'Our Stack': 'Nuestro Stack',
    'Get in contact with us': 'Ponte en contacto con nosotros',
    'We will be delighted to help you!': '¡Estaremos encantados de ayudarte!',
    'Tell us what you need': 'Cuéntanos qué necesitas',
    'Agile and efficient': 'Ágiles y eficaces',
    'Name': 'Nombre',
    'E-mail': 'Email',
    'Phone': 'Teléfono',
    'Why are you contacting us?': '¿Por qué razón nos contactas?',
    'Subject': 'Asunto',
    'Write your message': 'Escribe tu mensaje',
    'Your message': 'Tu mensaje',
    'Please enter your name': 'Por favor ingresa tu nombre',
    'Enter your phone number': 'Ingresa tu número telefónico',
    'Give us your email': 'Danos tu correo electronico',
    'Send': 'Enviar',
    'Ready to start your project?': '¿Listo para comenzar tu proyecto?',
    'We are ready to answer your questions': 'Estamos listos para responder a tus preguntas',
    'Our Story': 'Nuestra Historia',
    'Our Mission': 'Nuestra Misión',
    'CEO & Founder': 'CEO & Fundador',
    'CTO & Founder': 'CTO & Fundador',
    'Software Engineer': 'Ingeniero de Software',
    'Project Manager': 'Gerente de Proyecto',
    'Company': 'Compañia',
    'All rights reserved.': 'Todos los derechos reservados.',
    'Oops!': 'Woops!',
    '404 - Page not found': '404 - Pagina no encontrada',
    'The page you are looking for might have been removed had its name changed or is temporarily unavailable.': 'Es posible que la página que estás buscando se haya eliminado, se cambió de nombre o no está disponible temporalmente.',
    'Go to Homepage': 'Ir a la pagina principal',

    // Footer
    "Do you have an innovative idea and need help to develop it? At Startblock we are here to help you. We are the blockchain experts you have been looking for.": '¿Tienes una idea innovadora y necesitas ayuda para desarrollarla? En Startblock estamos aquí para ayudarte. Somos los expertos en blockchain que has estado buscando.',
    
    // Banner
    'The blockchain experts your idea needs': 'Los expertos en blockchain que tu idea necesita',
    "You have an innovative idea and need help building it? At Startblock we are here to help you. We’re the blockchain experts you have been looking for to carry that product from an idea to a solution that will make your customers' life easier and happier.": '¿Tienes una idea innovadora y necesitas ayuda para desarrollarla? En Startblock estamos aquí para ayudarte. Somos los expertos en blockchain que han estado buscando, para llevar ese producto de una idea a una solución que hará la vida de sus clientes más fácil y feliz.',
    
    // About
    "Startblock is a company dedicated to helping entrepreneurs and companies around the world make their ideas come true hand in hand with technology.": 'Startblock es una empresa dedicada a ayudar a emprendedores y empresas de todo el mundo a hacer realidad sus ideas de la mano de la tecnología.',
    'We have worked on projects across five continents, in several business areas such as telecommunication, agriculture, real estate and more. Adapting to your needs will not be a problem. Our mission will always be that your project is successful from its initial stages of gestation.': 'Hemos trabajado en proyectos en cinco continentes, en varias áreas de negocio como telecomunicaciones, agricultura, inmobiliaria y más. Adaptarse a tus necesidades no será un problema. Nuestra misión será siempre que tu proyecto sea exitoso desde sus etapas iniciales de gestación.',
    'We can help you close the technology gap that always exists between a good idea and its implementation.': 'Podemos ayudarte a cerrar la brecha tecnológica que siempre existe entre una buena idea y su implementación.',
    
    'We started in 2018, bringing together a group of professionals with a great passion for technology. Since then we have given our clients the tools to turn their ideas into sustainable businesses with the help of blockchain technology.': 'Comenzamos en el 2018, juntando a un grupo de profesionales con una gran pasión por la tecnología. Desde entonces hemos dado a nuestros clientes las herramientas que convierten sus ideas en negocios sustentables con la ayuda de la tecnología blockchain.',
    'Help entrepreneurs and companies transform their ideas and products into digital solutions that can leverage blockchain technology to their advantage. Our passion will always be to take our clients to the next level of the digital age.': 'Ayudar a los emprendedores y empresas a transformar sus ideas y productos en soluciones digitales que puedan aprovechar la tecnología blockchain para su beneficio. Nuestra pasión siempre será llevar a nuestros clientes al siguiente nivel de la era digital.',
    "We are people that laugh and work hard. We can do anything because we are an interdisciplinary group with skills in software engineering and passion for innovation. We'll use every tool in our box to make your project succeed.": 'Somos personas que reímos y trabajamos duro. Podemos hacer cualquier cosa porque somos un grupo interdisciplinario con habilidades en ingeniería de software y pasión por la innovación. Usaremos todas las herramientas para que su proyecto tenga éxito.',
    
    // Boxes Area
    'WE EVALUATE HOW BLOCKCHAIN CAN HELP': 'EVALUAMOS CÓMO AYUDA BLOCKCHAIN',
    'From a decision-making algorithm, we identify the potential that blockchain has in your organization and we design an application proposal.': 'A partir de un algoritmo de toma de decisiones, identificamos el potencial que tiene blockchain en su organización y diseñamos una propuesta de aplicación.',
    'WE DEVELOP SOLUTIONS FOR YOUR BUSINESS': 'DESARROLLAMOS SOLUCIONES PARA TU NEGOCIO',
    'We develop software that uses blockchain technology to advance your business objectives and help you create innovative and impactful products.': 'Desarrollamos software que utiliza tecnología blockchain para avanzar en sus objetivos comerciales y ayudarlo a crear productos innovadores e impactantes.',
    'WE TRAIN AND EDUCATE ABOUT BLOCKCHAIN': 'ENTRENAMOS Y EDUCAMOS SOBRE BLOCKCHAIN',
    'We provide talks and high-impact in-company courses designed to provide your staff with the necessary knowledge for the benefit of your business.': 'Brindamos charlas y cursos in-company de alto impacto diseñados para brindar a su personal los conocimientos necesarios en beneficio de su negocio.',
   

    // SERVICES AREA,
    'Our team of consultants and development experts is ready to take your idea from the conceptualization phase to a web app that will change your life and that of your clients.': 'Nuestro equipo de asesores y expertos en desarrollo está listo para llevar tu idea desde la fase de conceptualización hasta una web app que cambiará tu vida y la de tus clientes.',
    'We believe in collaboration with the client as part of the development within the extension of a mutual agreement.': 'Creemos en la colaboración con el cliente como parte del desarrollo dentro de la extensión de un mutuo acuerdo.',
    'We respond with ease and agility to changes without losing sight of the general vision that a defined strategy gives us.': 'Respondemos con facilidad y agilidad a los cambios sin perder de vista la visión general que una estrategia definida nos brinda.',
    'Our highest priority is to satisfy the customer through early and continuous delivery of valuable software.': 'Nuestra mayor prioridad es satisfacer al cliente mediante la entrega temprana y continua de software con valor.',
    "What are you waiting for? Let's talk about how we can change the world together.": '¿Qué esperas? Hablemos de cómo podemos cambiar el mundo juntos',
    
    "Our priority is software that works,": "Nuestra prioridad es el software funcionando,",
    "understanding that": "entendiendo que",
    "something functional is something proven,": "algo funcional es algo probado,",
    "so you can always expect progress week by week that you’ll be able to test out.": "por lo que podrás esperar siempre avances semana a semana.",
    
    // SERVICES
    'We know that every idea and business is different, so we have prepared a list of services that we can customize to your needs, no matter what phase you are in. Tell us what you need and we will get to work:': 'Sabemos que cada idea y negocio es diferente, así que hemos preparado una lista de servicios que podemos personalizar a tus necesidades, sin importar la fase en la que te encuentres. Cuéntanos que necesitas y nosotros nos pondremos manos a la obra:',
    'Smart Contracts': 'Smart Contracts',
    'Private Blockchain Networks': 'Redes Blockchain Privadas',
    'Mobile and Web DApps': 'D-Apps moviles y web',
    'Validation Systems': 'Sistemas de validación',
    'Crypto Payment Solutions': 'Soluciones para cryptopagos',

    // TEAM
    'Entrepreneur and engineer focused on technology and project management areas. My job is to ensure that our team reaches its maximum potential and that our clients obtain the maximum results. Committed to a more connected and more decentralized world.': 'Emprendedor e ingeniero enfocado en áreas de tecnología y gerencia de proyectos. Mi trabajo es buscar que nuestro equipo alcance su máximo potencial y que nuestros clientes obtengan los máximos resultados.  Comprometido con un mundo más conectado y más descentralizado.',
    'Software developer and innovator. Passionate about decentralized technologies. I seek to provide solutions to complex problems and I am a believer in free software. Part of my time is dedicated to guiding others in the areas of knowledge that I master.': 'Desarrollador de software, innovador, apasionado por las tecnologías descentralizadas. Busco dar soluciones a problemas complejos y soy creyente del software libre. Parte de mi tiempo lo dedico a guiar a otros en las áreas de conocimiento que domino.',
    'Production Engineer, creative and with experience in technology, consulting, supply chain and project management. I interpret the requirements in tangible products, closing the gap between an idea, its development and implementation.': 'Ingeniero de Producción con experiencia en áreas de tecnología, consultoría, cadena de suministros y gerencia. Creativa, interpreto los requerimientos en productos tangibles, cerrando la brecha entre una idea, su desarrollo y puesta en marcha.',
    'As a developer, I consider myself skilled at listening, solving problems and complicated situations that arise along the way. In my current team I am the one in charge of supporting. For me, programming is equivalent to playing with legos.': 'Como desarrollador me considero hábil para escuchar, solucionar problemas y situaciones complicadas que surjan en el camino. En mi equipo actual soy el que se encarga de apoyar. Para mí, la programación es equivalente a jugar con legos.',
    'Working as a software engineer, I have participated in the creation of multiple projects and I have seen first-hand how ideas become reality. I have always found the magic that happens inside the screen of any device interesting.': 'Trabajando como Ing. de software, he participado en la creación de múltiples proyectos y he visto de primera mano como ideas se vuelven realidad. Desde siempre me ha parecido interesante la magia que ocurre dentro de la pantalla de cualquier dispositivo.',
    'I have the necessary abstraction to solve software problems, propose ideas and solutions that, as a team, allow projects to be successful. What I have achieved so far has been thanks to the motivation to make every dream possible.': 'Poseo la abstracción necesaria para resolver problemas de software, proponer ideas y soluciones que, en equipo, permitan alcanzar el éxito de los proyectos. Lo que he logrado hasta ahora ha sido gracias a la motivación de hacer posible cada sueño.',
    "Web developer, motivated by the achievements of applying existing logic and technologies. With a willingness to learn multiple tools for better performance, and thus, together with my team, fulfill any proposed objective.":'Desarrolladora web, motivada por los logros de aplicar la lógica y las tecnologías existentes. Con disposición de aprender múltiples herramientas para mejor desempeño, y así en conjunto con mi equipo, cumplir cualquier objetivo propuesto',
  },
});
