<template>
    <!-- Start Main Banner  {{ $t('') }}-->
    <div class="main-banner">
        <div class="d-table">
            <div class="d-table-cell">
                <div class="container">
                    <div class="row h-100 justify-content-center align-items-center">
                        <div class="col-lg-5">
                            <div class="hero-content justify-center">
                                <h1>{{ $t('The blockchain experts your idea needs') }}</h1>
                                <p style="text-align: justify"> {{ $t("You have an innovative idea and need help building it? At Startblock we are here to help you. We’re the blockchain experts you have been looking for to carry that product from an idea to a solution that will make your customers' life easier and happier.") }}</p>
                                <a class="btn btn-primary" > <router-link to="/contact">{{ $t('Contact Us') }}</router-link> </a>
                            </div>
                        </div>

                        <div class="col-lg-6 offset-lg-1">
                            <div class="banner-image">
                                <img src="../../assets/img/banner-image/man.png" class="wow fadeInDown" v-wow data-wow-delay="1s" alt="man">
                                <img src="../../assets/img/banner-image/code.png" class="wow fadeInUp" v-wow data-wow-delay="1s" alt="code">
                                <img src="../../assets/img/banner-image/carpet.png" class="wow fadeInLeft" v-wow data-wow-delay="1s" alt="carpet">
                                <img src="../../assets/img/banner-image/bin.png" class="wow zoomIn" v-wow data-wow-delay="1s" alt="bin">
                                <img src="../../assets/img/banner-image/book.png" class="wow bounceIn" v-wow data-wow-delay="1s" alt="book">
                                <img src="../../assets/img/banner-image/dekstop.png" class="wow fadeInDown" v-wow data-wow-delay="1s" alt="dekstop">
                                <img src="../../assets/img/banner-image/dot.png" class="wow zoomIn" v-wow data-wow-delay="1s" alt="dot">
                                <img src="../../assets/img/banner-image/flower-top-big.png" class="wow fadeInUp" v-wow data-wow-delay="1s" alt="flower-top-big">
                                <img src="../../assets/img/banner-image/flower-top.png" class="wow rotateIn" v-wow data-wow-delay="1s" alt="flower-top">
                                <img src="../../assets/img/banner-image/keyboard.png" class="wow fadeInUp" v-wow data-wow-delay="1s" alt="keyboard">
                                <img src="../../assets/img/banner-image/pen.png" class="wow zoomIn" v-wow data-wow-delay="1s" alt="pen">
                                <img src="../../assets/img/banner-image/table.png" class="wow zoomIn" v-wow data-wow-delay="1s" alt="table">
                                <img src="../../assets/img/banner-image/tea-cup.png" class="wow fadeInLeft" v-wow data-wow-delay="1s" alt="tea-cup">
                                <img src="../../assets/img/banner-image/headphone.png" class="wow rollIn" v-wow data-wow-delay="1s" alt="headphone">

                                <img src="../../assets/img/banner-image/main-pic.png" class="wow fadeInUp" v-wow data-wow-delay="1s" alt="main-pic">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="shape1"><img src="../../assets/img/shape1.png" alt="shape"></div>
        <div class="shape2 rotateme"><img src="../../assets/img/shape2.png" alt="shape"></div>
        <div class="shape3"><img src="../../assets/img/shape3.png" alt="shape"></div>
        <div class="shape4"><img src="../../assets/img/shape4.png" alt="shape"></div>
        <div class="shape5"><img src="../../assets/img/shape5.png" alt="shape"></div>
        <div class="shape6 rotateme"><img src="../../assets/img/shape4.png" alt="shape"></div>
        <div class="shape7"><img src="../../assets/img/shape4.png" alt="shape"></div>
        <div class="shape8 rotateme"><img src="../../assets/img/shape2.png" alt="shape"></div>
    </div>
    <!-- End Main Banner -->
</template>

<script>
export default {
    name: 'Banner'
}
</script>