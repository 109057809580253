<template>
    <!-- Start Navbar Area -->
    <header id="header" :class="['headroom', {'is-sticky': isSticky}]">
        <div class="startp-nav">
            <div class="container">
                <nav class="navbar navbar-expand-md navbar-light">
                    <router-link class="navbar-brand" to="/">
                        <img src="../../assets/img/logo.png" alt="logo" />
                    </router-link>

                    <b-navbar-toggle target="navbarSupportedContent"></b-navbar-toggle>


                    <b-collapse class="collapse navbar-collapse mean-menu" id="navbarSupportedContent" is-nav>
                        <ul class="navbar-nav nav ml-auto">

                            <li class="nav-item">
                               <router-link to="/" class="nav-link">{{ $t('Home') }}</router-link>
                            </li>
  
                            <li class="nav-item">
                               <router-link to="/about" class="nav-link">{{ $t('About Us') }}</router-link>
                            </li>
                
                            <li class="nav-item">
                                <router-link to="/contact" class="nav-link">{{ $t('Contact Us') }}</router-link>
                            </li>
                            <li>
                                <!-- UK Flag Button -->
                                <div class="flag-uk mr-2 mt-2">
                                    <img
                                    src="@/assets/img/flag_uk.svg"
                                    id="userDropdown"
                                    alt
                                    v-on:click="showAppIn('en')"
                                    />
                                </div>
                            </li>
                            <li>
                                <!-- ESP Flag Button -->
                                <div class="flag-pl mt-2">
                                    <img
                                    src="@/assets/img/flag_es.svg"
                                    id="userDropdown"
                                    alt
                                    v-on:click="showAppIn('es')"
                                    />
                                </div>
                            </li>
                        </ul>
                    </b-collapse>
                </nav>
            </div> 
        </div>
    </header>
    <!-- End Navbar Area -->
</template>

<script>
export default {
    name: 'Header',
    data(){
        return {
            isSticky: false
        }
    },

    mounted() {
        const that = this;
        window.addEventListener('scroll', () => {
            let scrollPos = window.scrollY;
            // eslint-disable-next-line no-console
            if(scrollPos >= 100){
                that.isSticky = true;
            } else {
                that.isSticky = false;
            }
        })
    },

    computed: {
        shoppingCart(){
            return this.$store.state.cart;
        }
    },

    methods:{
        showAppIn(lang) {
            this.$polyglot.setLang({ lang });
        },
    },
};
</script>
<style scoped>
.flag-uk, .flag-pl{
  cursor: pointer;
  /*margin-top: -5px*/
}

</style>